import React from "react"
import { Link } from "gatsby";
import { AppBar, Toolbar, styled, useTheme } from '@mui/material'
import Button from "components/material-dashboard-pro-react/components/CustomButtons/Button"
import LogoPiramide from "../../static/logo-piramides.svg"
import LogoOceanica from "../../static/oceanica_original.png"
import admBarStyles from "../Portal/Layout/Piramide/adminNavbarStyle"

const StyledDiv = styled("div")(({ theme }) => ({
  display: "flex",
  height: '100vh',
  width: '100wh',
  justifyContent: "center",
  alignItems: "center",
}))

const AppBarStyled = styled(AppBar)(({ theme }) => {
  const newStyles = admBarStyles(theme)
  return {
    ...newStyles.appBar,
    ...newStyles.absolute,
  }
})

const Container = styled('div')(({ theme }) => {
  const newStyles = admBarStyles(theme)
  return {
    ...newStyles.flex
  }
})

const NewDivContent = styled('div')(({ theme }) => {
  const newStyles = admBarStyles(theme)
  return {
    ...newStyles.content
  }
})

const Image = styled('img')(({ theme }) => {
  const newStyles = admBarStyles(theme)
  return {
    ...newStyles.logo
  }
})




export default function NotFoundPage(){
  const insuranceCompany = process.env.GATSBY_INSURANCE_COMPANY
  const theme = useTheme()
  const classes = admBarStyles(theme)
  return (

<NewDivContent>
      <AppBarStyled>
        <Toolbar sx={classes.container}>
          <Container>
            <Button className={classes.button__logo} color="transparent">
              {(insuranceCompany === 'OCEANICA')
                ? <Link to="/"><Image src={LogoOceanica} alt="Oceánica de Seguros" /></Link>
                : <Link to="/"><Image src={LogoPiramide} alt="Pirámide Seguros" /></Link>
              }
            </Button>
          </Container>
        </Toolbar>
      </AppBarStyled>
      <StyledDiv>
        <h2>¡Portal en Mantenimiento!</h2>
      </StyledDiv>
    </NewDivContent>


    
  )
}
